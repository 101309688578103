import axios from "axios";
import { push } from "connected-react-router";

export const baseAuth = user => {
  let { username, password } = user;
  return window.btoa(username + ":" + password);
};

export const checkEmptyObject = obj => {
  if (Object.entries(obj).length === 0 && obj.constructor === Object) {
    return true;
  } else return false;
};

export const checkEmptyArray = arr => {
  if (arr.length === 0) {
    return true;
  } else return false;
};

export const keyValue = (arr, a, b) => {
  let obj = {};
  arr.forEach(e => {
    obj[e[a]] = e[b];
  });
  return obj;
};

export const getSibiling = (arr, a, b, value) => {
  let obj = keyValue(arr, a, b);

  return obj[value];
};

export const makeid = length => {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const makeOfflineId = () => {
  return `offline-${makeid(10)}`;
};

export const fetchMethod = url => {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(res => {
        return resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const dispatchPromise = (dispatch, type, data) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: type,
      payload: data
    });
    return resolve(data);
  });
};

export const dispatchError = (dispatch, type, error) => {
  dispatch({
    type: type,
    payload: error
  });
  if (error.response && error.response.status === 401) {
    return dispatch(push("/login"));
  }
};

export const dispatchSuccess = (dispatch, type, data, pushTo, saveLocal) => {
  dispatch({
    type: type,
    payload: data
  });
  if (saveLocal) {
    localStorage.setItem(saveLocal, JSON.stringify(data));
  }
  if (pushTo) {
    return dispatch(push(pushTo));
  }
};

export const dispatchInit = (dispatch, type) => {
  dispatch({
    type: type
  });
};

export const genSearchString = search => {
  let searchString = "";
  Object.keys(search).forEach(e => {
    searchString += `&${e}=${search[e]}`;
  });

  return searchString;
};

export const genFilterString = filter => {
  let filterString = "activityFilter=";
  Object.keys(filter).forEach(e => {
    filterString += `${filter[e]["parentId"]}%20eq%20${filter[e]["id"]},`;
  });
  return filterString;
};

export const genQuery = (search, filter) => {
  let query = "";
  let searchString = genSearchString(search);
  let filterString = genFilterString(filter);
  if (filterString !== "activityFilter=") {
    filterString = filterString.slice(0, -1);
    query += filterString;
  }
  query += searchString;
  return query;
};

// A function to return date as per user's selected language.
// English is the default language.
export const getLocaleDate = (date) => {
  let d = new Date(date);
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return d.toLocaleDateString(localStorage.getItem("lng") || "en", options);
};

// A function to return the date in custom format.
// date (optional): if not, supply then return format of today's date.
// format (optional): if not, supply then return default format (yyyy-mm-dd).
export const getDateInFormat = (date, format) => {
  let d = date !== undefined && date !== null ? new Date(date) : new Date();
  let dd = ("0" + d.getDate()).slice(-2);
  let mm = ("0" + (d.getMonth() + 1)).slice(-2);
  let yyyy = d.getFullYear();
  switch (format) {
    // Custom formats
    case 1:
      return `${dd}/${mm}/${yyyy}`;
    case 2:
      return `${mm}/${dd}/${yyyy}`;

    default:
      // UTC standard
      return `${yyyy}-${mm}-${dd}`;
  }
};

// A function to return the date after adding provided days.
// return date format is yyyy-mm-dd.
export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return getDateInFormat(result);
}

// Iterate through each element in the 
// first array and if some of them  
// include the elements in the second 
// array then return true. 
export const isSomeCommonElements = (arr1, arr2) => {
  return arr1.some(item => arr2.includes(item))
} 
